import api from "@/services/api";
import { Module } from "vuex";
import { OnDemandState, FetchOnDemandEventsParams } from "./model";
import { onDemand } from "@/services/api/requests/onDemand";

const OnDemandModule: Module<OnDemandState, unknown> = {
  namespaced: true,
  state(): OnDemandState {
    return {
      showTiLoader: false,
      onDemandEvent: {
        courseId: "",
        contentViewedByPercent: 0,
        passedAssessments: 0,
        percentComplete: 0,
        totalAssessments: 0,
        totalHours: 0,
        certificateUrl: null,
      },
      onDemandLearnerStats: {
        courseId: "",
        totalRedeemedLearners: 0,
        completedLearners: 0,
        inProgressLearners: 0,
        notStartedLearners: 0,
        totalQuizzes: 0,
        allocatedLicenses: 0,
        averageHours: "",
      },
    };
  },
  getters: {},
  mutations: {
    setOnDemandState(state: OnDemandState, changes: Partial<OnDemandState>): void {
      Object.assign(state, changes);
    },
  },
  actions: {
    async fetchOnDemandEventProgress({ commit }, { userId, courseId }: FetchOnDemandEventsParams): Promise<void> {
      const onDemandEvent = await api.onDemand.eventProgress(userId, courseId);
      onDemandEvent.courseId = courseId;
      commit("setOnDemandState", { onDemandEvent: onDemandEvent });
    },
    async populateLearnerDetails({ commit }, params: { onDemandEventId: string; courseId: string }): Promise<void> {
      const res = await onDemand.eventLearnersStats(params.onDemandEventId, params.courseId);
      if (res) commit("setOnDemandState", { onDemandLearnerStats: res });
    },
  },
};
export default OnDemandModule;
